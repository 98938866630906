.sidebar {
  background-color: white !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  background-color: white !important;
}
.sidebarWrapper {
  padding: 10px;
  margin-top: 40px;
  background-color: white !important;
}

.sidebarTitle {
  font-size: 14px;
  color: #ced4da;
  display: none;
  font-family: monospace;
  font-weight: 600;
  text-transform: uppercase;
}
.sidebarList {
  list-style: none;
  padding: 5px;
}
.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  border-radius: 19px 0px;
  color: black;
}
li.sidebarListItem:hover {
  margin-top: 1%;
  background-color: #8f00ff;
  color: white !important;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

a.link.active .sidebarListItem {
  background-color: #8f00ff;
  padding-left: 12px;
  margin-top: 3px;
  color: white !important;
  margin-bottom: 3px;
  font-weight: 600;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.sidebarIcon {
  margin-right: 15px;
  font-size: 20px !important;
}

.sidebarSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sidebarSelectHead {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}
.sidebarSelectList {
  padding-left: 9px;
  margin-top: 10px;
}
.sidebarSelectList div {
  display: flex;
  padding: 8px 8px 10px 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  border-left: 1px solid #ced4da;
}

.sidebarSelectList .active div {
  color: #8f00ff;
  background-color: #f4effa;
  border-left: 1px solid #8f00ff;
}

.arrowIcon {
  font-size: 22px !important;
  color: #6c757d;
  transition: transform 0.2s ease-in-out; /* Adjust the duration and timing function as needed */
}


.sidebarSelectHead .icon {
  font-size: 22px !important;
  color: #6c757d;
}

.sidebarSelectHead span {
  font-size: 14px;
  color: #0a0908;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}
