.topNavigator{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin-bottom: 10px;
    margin-top: 50px;
    border-radius: 0;
}
.pTag{
    font-weight: 600;
    font-size: 14px;
    margin-left: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


